import { Box, Flex, Grid, Checkbox, Button, Collapse } from "@mantine/core";
import { HiMiniAdjustmentsHorizontal } from "react-icons/hi2";

const TopFilterSection = ({
  activeRouteName,
  checked,
  setChecked,
  setApproveAll,
  data,
  classes,
  openModalApproveMassive,
  approveAll,
  opened,
  topFilterData,
  toggle,
  WCInlineInputFilter,
  hasMassiveLegalization,
  checkedLeg,
  setCheckedLeg,
  setApproveAllLeg,
  approveAllLeg,
}) => {
  return (
    <>
      <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto">
        <Flex
          my={16}
          justify={
            activeRouteName !== "viajes-por-aprobar"
              ? "flex-end"
              : "space-between"
          }
          align="center"
        >
          {activeRouteName !== "viajes-por-aprobar" ? null : (
            <Grid justify="center" align="center">
              <Grid.Col span="content">
                {hasMassiveLegalization ? (
                  <Checkbox
                    checked={checkedLeg}
                    onChange={() => {
                      if (checkedLeg) {
                        setCheckedLeg(false);
                        setApproveAllLeg([]);
                      } else {
                        setCheckedLeg(true);
                        setApproveAllLeg(
                          data.requestData.filter(
                            (request) => request.fkEstadoViaje === "LEGALIZENV"
                          )
                        );
                      }
                    }}
                    className={classes.checkBox}
                    label="Aprobar legalizaciones masivamente (seleccionar todos)"
                  />
                ) : (
                  <Checkbox
                    checked={checked}
                    onChange={() => {
                      if (checked) {
                        setChecked(false);
                        setApproveAll([]);
                      } else {
                        setChecked(true);
                        setApproveAll(
                          data.requestData.filter(
                            (request) => request.fkEstadoViaje === "PENDAPROBO"
                          )
                        );
                      }
                    }}
                    className={classes.checkBox}
                    label="Aprobar masivamente (seleccionar todos)"
                  />
                )}
              </Grid.Col>
              {(!!approveAll.length || !!approveAllLeg.length) && (
                <Grid.Col span="content">
                  <Button
                    className={classes.button}
                    onClick={openModalApproveMassive}
                  >
                    Aprobar Masivamente
                  </Button>
                </Grid.Col>
              )}
            </Grid>
          )}

          <Button className={classes.button} onClick={toggle}>
            <HiMiniAdjustmentsHorizontal />
          </Button>
        </Flex>
      </Box>
      <Collapse in={opened}>
        <WCInlineInputFilter inputs={topFilterData} />
      </Collapse>
    </>
  );
};

export default TopFilterSection;
