import {
  Flex,
  Text,
  Badge,
  Grid,
  TextInput,
  Checkbox,
  Button,
} from "@mantine/core";
import { BiSolidPlusCircle, BiSolidMinusCircle } from "react-icons/bi";
import { Fragment } from "react";

const CardDetail = ({
  GlobalCard,
  isMd,
  showDetails,
  setShowDetails,
  classes,
  findTravelName,
  utils,
  validateValidity,
  path,
  handleChangeViaticum,
  approveAll,
  setApproveAll,
  filterButtonStates,
  dataUser,
  data,
  hasMassiveLegalization,
  approveAllLeg,
  setApproveAllLeg,
}) => {
  const renderCity = (city, label) => {
    return (
      <Grid.Col span={6}>
        <TextInput
          label={label}
          value={city}
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          disabled={true}
        />
      </Grid.Col>
    );
  };
  return (
    <>
      {data.map((requestItem, index) => (
        <GlobalCard
          key={index}
          styles={{
            position: "relative",
            marginBottom: "36px",
          }}
        >
          <Flex
            justify="center"
            align="center"
            gap="4px"
            sx={(theme) => ({
              [theme.fn.smallerThan("md")]: {
                paddingTop: "20px",
                flexDirection: "column",
              },
            })}
            mb={12}
          >
            <Flex align="center" gap={4}>
              <Text color="#004236" fw={700} fs="md">
                ID Viaje: {requestItem.id}
              </Text>
              {isMd &&
                (showDetails ? (
                  <BiSolidMinusCircle
                    size="1.25rem"
                    style={{
                      flexShrink: 0,
                      color: "#cbd300",
                    }}
                    onClick={() => setShowDetails(false)}
                    data-testid="hide-details-button"
                  />
                ) : (
                  <BiSolidPlusCircle
                    size="1.25rem"
                    style={{
                      flexShrink: 0,
                      color: "#cbd300",
                    }}
                    onClick={() => setShowDetails(true)}
                    data-testid="show-details-button"
                  />
                ))}
            </Flex>
            <Badge
              sx={{
                backgroundColor: "#cbd300",
                color: "#004236",
              }}
            >
              {requestItem.listaEstados?.find(
                (status) =>
                  status.paramFkEstadoViaje === requestItem.fkEstadoViaje
              )?.paramNombreEstado || "Sin Estado"}
            </Badge>
          </Flex>

          {requestItem?.tieneViaticoPermanente && (
            <Badge
              sx={{
                backgroundColor: "#ed7703",
                color: "#fff",
                position: "absolute",
                left: 0,
                top: 20,
                borderRadius: "0 2rem 2rem 0",
              }}
            >
              Viaje con viático permanente
            </Badge>
          )}

          {requestItem?.hasNovelty && (
            <Badge
              sx={{
                backgroundColor: "#ed7703",
                color: "#fff",
                position: "absolute",
                left: 0,
                top: 20,
                borderRadius: "0 2rem 2rem 0",
              }}
            >
              Con novedad
            </Badge>
          )}

          {(isMd ? showDetails : true) && (
            <Grid align="center">
              <Grid.Col span={6}>
                <TextInput
                  label="Viajero"
                  value={requestItem.funcionariosNombreViajero}
                  classNames={{
                    label: classes.labelSecondary,
                    input: classes.input,
                  }}
                  disabled={true}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Tipo de viaje"
                  value={findTravelName(requestItem?.paramTipoViaje)}
                  classNames={{
                    label: classes.labelSecondary,
                    input: classes.input,
                  }}
                  disabled={true}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Fecha inicio"
                  value={utils.parseDateLocaleFormat(requestItem.fechaInicio)}
                  classNames={{
                    label: classes.labelSecondary,
                    input: classes.input,
                  }}
                  disabled={true}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Fecha Fin"
                  value={utils.parseDateLocaleFormat(requestItem.fechaFin)}
                  classNames={{
                    label: classes.labelSecondary,
                    input: classes.input,
                  }}
                  disabled={true}
                />
              </Grid.Col>

              {(requestItem.multiviaje
                ? requestItem.listaDestinos
                : [requestItem.listaDestinos[0]]
              ).map((city, cityIndex) => (
                <Fragment key={cityIndex}>
                  {renderCity(city.nombreCiudadOrigen, "Ciudad Origen")}
                  {renderCity(city.nombreCiudadDestino, "Ciudad Destino")}
                </Fragment>
              ))}

              {validateValidity(requestItem) && (
                <Grid.Col span={6}>
                  <TextInput
                    label="Vigencia de la reserva"
                    classNames={{
                      label: classes.labelSecondary,
                      input: classes.input,
                    }}
                    disabled={true}
                    value={
                      requestItem?.serviciosViaje?.length > 0
                        ? utils
                            .moment(
                              requestItem?.vigenciaReserva,
                              "DD/MM/YYYY h:mm a"
                            )
                            .locale("es")
                            .format("DD-MMM-YYYY hh:mm A")
                        : requestItem?.vigenciaReserva
                    }
                  />
                </Grid.Col>
              )}
              {Object.keys(requestItem?.legalizacion || {}).length > 0 ? (
                <>
                  <Grid.Col span={12}>
                    <Text fz="sm" fw={700} c="#004236" ta="center">
                      Valores totales
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={12} md={4}>
                    <TextInput
                      label="Valor anticipo"
                      classNames={{
                        label: classes.labelSecondary,
                        input: classes.input,
                      }}
                      disabled={true}
                      value={utils.formatter.format(
                        requestItem?.legalizacion?.valorAbonadoCop
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} md={4}>
                    <TextInput
                      label="Valor total del viaje"
                      classNames={{
                        label: classes.labelSecondary,
                        input: classes.input,
                      }}
                      disabled={true}
                      value={utils.formatter.format(
                        requestItem?.legalizacion?.totalLiquidacion
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} md={4}>
                    <TextInput
                      label={
                        (requestItem?.legalizacion?.tipoLegalizacion ===
                          "CERO" &&
                          "Saldo en cero") ||
                        (requestItem?.legalizacion?.tipoLegalizacion ===
                          "MENOR" &&
                          "Valor a favor de la empresa") ||
                        (requestItem?.legalizacion?.tipoLegalizacion ===
                          "MAYOR" &&
                          "Valor a favor del viajero")
                      }
                      classNames={{
                        label: classes.labelSecondary,
                        input: classes.input,
                      }}
                      disabled={true}
                      value={utils.formatter.format(
                        requestItem?.legalizacion?.totalAveLegCop
                      )}
                    />
                  </Grid.Col>
                </>
              ) : (
                <Grid.Col span={validateValidity(requestItem) ? 6 : 12}>
                  <TextInput
                    label="Valor Total"
                    classNames={{
                      label: classes.labelSecondary,
                      input: classes.input,
                    }}
                    disabled={true}
                    value={utils.formatter.format(
                      requestItem?.anticipoViaje[0]?.totalConServiciosCop
                    )}
                  />
                </Grid.Col>
              )}
              {path === "viajes-aprobados-por-cerrar" && (
                <>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Días en mora"
                      value={requestItem.diasEnMora}
                      classNames={{
                        label: classes.labelSecondary,
                        input: classes.input,
                      }}
                      disabled={true}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Fecha Máxima Legalización"
                      value={utils.parseDateLocaleFormat(
                        requestItem.fechaMaximaLegalizacion
                      )}
                      classNames={{
                        label: classes.labelSecondary,
                        input: classes.input,
                      }}
                      disabled={true}
                    />
                  </Grid.Col>
                </>
              )}
              {requestItem?.paramTipoViaje === "TVSINDICATOS" &&
                path === "viajes-por-aprobar" &&
                dataUser?.datosUsuario?.permisosUnicos?.includes(
                  "viatico Permanente"
                ) && (
                  <Grid.Col xs={12} md={6} sx={{ alignSelf: "center" }}>
                    <Checkbox
                      checked={requestItem?.tieneViaticoPermanente}
                      label="Viático permanente"
                      onChange={() =>
                        requestItem?.fkEstadoViaje === "PENDAPROBO" &&
                        handleChangeViaticum(requestItem)
                      }
                      className={classes.checkBox}
                      sx={{ label: { color: "#004236", fontWeight: 700 } }}
                    />
                  </Grid.Col>
                )}

              {requestItem.fkEstadoViaje === "LEGALIZENV" &&
                hasMassiveLegalization && (
                  <Grid.Col xs={12} md={6} sx={{ alignSelf: "center" }}>
                    <Checkbox
                      checked={approveAllLeg.some(
                        (item) => item.id === requestItem.id
                      )}
                      label="Agregar a la legalización masiva"
                      className={classes.checkBox}
                      sx={{ label: { color: "#004236", fontWeight: 700 } }}
                      onChange={() => {
                        if (
                          approveAllLeg.some(
                            (item) => item.id === requestItem.id
                          )
                        ) {
                          setApproveAllLeg(
                            approveAllLeg.filter(
                              (item) => item.id !== requestItem.id
                            )
                          );
                        } else {
                          setApproveAllLeg((prev) => [...prev, requestItem]);
                        }
                      }}
                    />
                  </Grid.Col>
                )}
              {requestItem.fkEstadoViaje === "PENDAPROBO" && (
                <Grid.Col xs={12} md={6} sx={{ alignSelf: "center" }}>
                  <Checkbox
                    checked={approveAll.some(
                      (item) => item.id === requestItem.id
                    )}
                    label="Agregar a la aprobación masiva"
                    className={classes.checkBox}
                    sx={{ label: { color: "#004236", fontWeight: 700 } }}
                    onChange={() => {
                      if (
                        approveAll.some((item) => item.id === requestItem.id)
                      ) {
                        setApproveAll(
                          approveAll.filter(
                            (item) => item.id !== requestItem.id
                          )
                        );
                      } else {
                        setApproveAll((prev) => [...prev, requestItem]);
                      }
                    }}
                  />
                </Grid.Col>
              )}

              <Grid.Col span={12}>
                <Flex align="center" gap={8} justify="flex-start" wrap="wrap">
                  {filterButtonStates(path, requestItem.fkEstadoViaje).map(
                    (item, index) => {
                      const shouldRenderButton =
                        !item?.validations ||
                        item.validations.every((val) => val === true);
                      return (
                        shouldRenderButton && (
                          <Button
                            key={index}
                            className={classes.button}
                            onClick={() => item.action(requestItem)}
                            sx={{
                              flex: 1,
                            }}
                          >
                            {item.name}
                          </Button>
                        )
                      );
                    }
                  )}
                </Flex>
              </Grid.Col>
            </Grid>
          )}
        </GlobalCard>
      ))}
    </>
  );
};

export default CardDetail;
